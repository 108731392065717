import { useState, useMemo } from "react";
import { Form } from "antd";
import {
  useIntl,
  BasicFilter,
  lodash as _,
} from "@datwyler/mfe-shared-components";
import FilterForm from "./Form";
import { AlarmFilterType, Severity, AlarmStatus } from "@types";

const AlarmFilter = ({
  locations,
  devices,
  setFilters,
  setPageNumber,
  users,
}: AlarmFilterType) => {
  const intl = useIntl();
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { resetFields } = form;

  const onFinish = (formData) => {
    const sites = _.get(formData, "site", []) || [];
    const assignedTo = _.get(formData, "assignedTo", []) || [];
    const deviceType = _.get(formData, "deviceType", []) || [];
    const severity = _.get(formData, "severity", []) || [];
    const status = _.get(formData, "status", []) || [];
    const [startDate, endDate] = _.get(formData, "dateRange", []) || [];

    const filters = {
      ...(sites.length > 0 && { site: sites.map((site) => ({ id: site })) }),
      ...(assignedTo.length > 0 && {
        assignedTo: assignedTo.map((user) => ({ id: user })),
      }),
      ...(deviceType.length > 0 && { deviceType }),
      ...(severity.length > 0 && { severity }),
      ...(status.length > 0 && { status }),
      ...(startDate && {
        startTime: startDate.startOf("day").unix(),
      }),
      ...(endDate && {
        endTime: endDate.endOf("day").unix(),
      }),
    };
    setFilters(filters);
    setOpen(false);
  };

  const onReset = () => {
    resetFields();
    setFilters({});
    setPageNumber(1);
  };

  const siteOptions = useMemo(() => {
    const options = [];
    locations.map((location) => {
      const sites = _.get(location, "sites", []) || [];
      sites.map((site) => {
        options.push({
          label: site.name,
          value: site.id,
        });
      });
    });
    return options;
  }, [locations]);

  const deviceOptions = useMemo(() => {
    const options = [];
    devices.map(({ type }) => {
      options.push({
        label: intl.formatMessage({ id: type }),
        value: type,
      });
    });
    return _.uniqBy(options, ({ value }) => {
      return value;
    });
  }, [intl, devices]);

  const levelOptions = useMemo(() => {
    const keys = Object.keys(Severity);
    return keys.map((key) => ({
      label: intl.formatMessage({ id: key }),
      value: key,
    }));
  }, [intl]);

  const statusOptions = useMemo(() => {
    const keys = Object.keys(AlarmStatus);
    return keys.map((key) => ({
      label: intl.formatMessage({ id: "alarm_" + key.toLowerCase() }),
      value: key,
    }));
  }, [intl]);

  const userptions = useMemo(() => {
    return users.map((user) => ({
      label: user.firstName + " " + user.lastName,
      value: user.id,
    }));
  }, [users]);
  return (
    <BasicFilter
      content={
        <FilterForm
          form={form}
          intl={intl}
          siteOptions={siteOptions}
          deviceOptions={deviceOptions}
          levelOptions={levelOptions}
          statusOptions={statusOptions}
          userptions={userptions}
          onFinish={onFinish}
          onReset={onReset}
        />
      }
      open={open}
      setOpen={setOpen}
    />
  );
};

export default AlarmFilter;
