import { useMemo } from "react";
import { Form, Select, Space, Button } from "antd";
import {
  BasicModal,
  commonStyles,
  styled,
} from "@datwyler/mfe-shared-components";
import { UserSearchType } from "@types";

const { Button16Invert } = commonStyles;

export const CancelButton = styled(Button)`
  height: 48px;
  color: ${(props) => props.theme.datwylerText.textPrimary};
  padding: 12px 24px;
`;

export default function UserSearchModal({
  users = [],
  openUserModal,
  setOpenUserModal,
  intl,
  handleAssingTo,
}: UserSearchType) {
  const [form] = Form.useForm();
  const dataSource = useMemo(() => {
    return users.map((user) => ({
      label: user.firstName + " " + user.lastName,
      value: user.id,
    }));
  }, [users]);

  const handleSubmit = ({ user }) => {
    handleAssingTo(user);
  };

  return (
    <BasicModal
      isOpen={openUserModal}
      title={intl.formatMessage({ id: "alarm_assign_to" })}
      setOpen={() => setOpenUserModal(false)}
      handleCancel={() => setOpenUserModal(false)}
      footer={() => {
        return (
          <Space>
            <CancelButton onClick={() => setOpenUserModal(false)}>
              {intl.formatMessage({ id: "cancel_btn" })}
            </CancelButton>
            <Button16Invert htmlType="submit" type="primary">
              {intl.formatMessage({ id: "upload_btn" })}
            </Button16Invert>
          </Space>
        );
      }}
      modalRender={(dom) => (
        <Form onFinish={handleSubmit} form={form}>
          {dom}
        </Form>
      )}
    >
      <Form.Item
        name="user"
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "alarm_user_validation_msg" }),
          },
        ]}
      >
        <Select
          style={{ width: "100%", height: 56 }}
          showSearch
          placeholder={intl.formatMessage({ id: "alarm_select_assignTo" })}
          suffixIcon={null}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={dataSource}
        />
      </Form.Item>
    </BasicModal>
  );
}
