import { useCallback, useEffect, useState } from "react";
import {
  useAlarm,
  useLocation,
  useDevice,
  useUser,
  lodash as _,
} from "@datwyler/mfe-shared-components";
import { AlarmStatus } from "@types";

const filterDefaults = {
  status: [AlarmStatus.ACTIVE, AlarmStatus.IN_PROGRESS],
};

const useAlarms = (tenantId: string) => {
  const [tenantID, setTenantID] = useState(tenantId);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState("time");
  const [filters, setFilters] = useState({});
  const {
    fetchAlarmData,
    fetchAlarms,
    assignAlarms,
    exportAlarms,
    exportAlarmsData,
    unassignAlarms,
    updateAlarms,
    updateAlarmResponseData,
    escalationHistory,
    escalationHistoryData,
  } = useAlarm();
  const { fetchLocationData, fetchLocations } = useLocation();
  const { fetchDeviceData, fetchDevices } = useDevice();
  const { fetchUsersData, fetchUsers } = useUser();

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId]);

  useEffect(() => {
    fetchAlarms({
      variables: {
        tenantId: tenantID,
        page: { number: pageNumber - 1, size: rowsPerPage * 1 },
        filter: { ...filterDefaults, ...filters },
        sort: [`${sortBy},desc`],
      },
    });
  }, [fetchAlarms, tenantID, sortBy, pageNumber, rowsPerPage, filters]);

  useEffect(() => {
    fetchLocations({
      variables: { tenantId: tenantID },
    });
  }, [tenantID, fetchLocations]);

  useEffect(() => {
    fetchDevices({
      variables: {
        tenantId: tenantID,
        page: { number: 0, size: 9999 },
      },
    });
  }, [tenantID, fetchDevices]);

  useEffect(() => {
    fetchUsers({
      variables: { tenantId: tenantID, page: { number: 0, size: 9999 } },
    });
  }, [tenantID, fetchUsers]);

  const assignAlarm = useCallback(
    (userId: string, alarmIDs: string[]) => {
      assignAlarms({
        variables: {
          input: {
            alarmIds: alarmIDs,
            assignee: { id: userId },
            tenant: { id: tenantID },
          },
        },
      });
    },
    [assignAlarms, tenantID]
  );

  const unassignAlarm = useCallback(
    (alarmIDs: string[]) => {
      unassignAlarms({
        variables: {
          input: { tenant: { id: tenantID }, alarmIds: alarmIDs },
        },
      });
    },
    [unassignAlarms, tenantID]
  );

  const exportAlarmsFile = useCallback(() => {
    exportAlarms({
      variables: {
        tenantId: tenantID,
        page: { number: 0, size: 9999999 },
        filter: { ...filterDefaults, ...filters },
        sort: [`${sortBy},desc`],
      },
    });
  }, [exportAlarms, tenantID, filters, sortBy]);

  const updateAlarm = useCallback(
    (alarmIDs: string[], status: AlarmStatus, message: string) => {
      updateAlarms({
        variables: {
          input: {
            alarmIds: alarmIDs,
            status: status,
            tenant: { id: tenantID },
            ...(message && { message }),
          },
        },
      });
    },
    [tenantID, updateAlarms]
  );

  const getEscalationLogs = useCallback(
    (alarmId) => {
      escalationHistory({
        variables: {
          tenantId: tenantID,
          alarmId: alarmId,
        },
      });
    },
    [tenantID, escalationHistory]
  );

  return {
    alarms: _.get(fetchAlarmData, "alarms.alarms", []),
    alarmsToExport: _.get(exportAlarmsData, "alarms.alarms", []),
    updatedAlarms: _.get(updateAlarmResponseData, "updateAlarms.alarms", []),
    locations: _.get(fetchLocationData, "locations", []),
    devices: _.get(fetchDeviceData, "devices.devices", []),
    users: _.get(fetchUsersData, "users.users", []),
    totalRows: _.get(fetchAlarmData, "alarms.page.totalElements", 0),
    escalationLogs: _.get(escalationHistoryData, "alarmEscalationLogs", []),
    sortBy,
    pageNumber,
    rowsPerPage,
    setPageNumber,
    setRowsPerPage,
    setFilters,
    setSortBy,
    assignAlarm,
    exportAlarmsFile,
    unassignAlarm,
    updateAlarm,
    getEscalationLogs,
  };
};

export default useAlarms;
