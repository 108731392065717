import {
  BasicDataTable,
  BasicModal,
  lodash as _,
} from "@datwyler/mfe-shared-components";
import { getValidDate } from "@helpers";
import { EscalationLogsType } from "@types";
const EscalationLogsTable = ({
  data = [],
  intl,
  open,
  setOpen,
}: EscalationLogsType) => {
  const columns = [
    {
      title: intl.formatMessage({ id: "alarm_id" }),
      key: "ID",
      dataIndex: "id",
    },
    {
      title: intl.formatMessage({ id: "alarm_message" }),
      key: "message",
      dataIndex: "message",
    },
    {
      title: intl.formatMessage({ id: "alarm_escalated_by" }),
      key: "escalated_by",
      render: (row) =>
        _.get(row, "escalatedBy.firstName", "") +
        " " +
        _.get(row, "escalatedBy.lastName", ""),
    },
    {
      title: intl.formatMessage({ id: "alarm_escalated_on" }),
      key: "escalated_on",
      render: (row) =>
        getValidDate(_.get(row, "escalatedOn", "-"), "DD-MM-YYYY HH:mm"),
    },
  ];
  return (
    <BasicModal
      isOpen={open}
      handleCancel={() => setOpen(false)}
      width="700px"
      footer={null}
      title="Escalation Logs"
    >
      <BasicDataTable columns={columns} data={data} />
    </BasicModal>
  );
};

export default EscalationLogsTable;
