import { Input, Form, Space, Button } from "antd";
import {
  BasicModal,
  commonStyles,
  styled,
} from "@datwyler/mfe-shared-components";
import { EscalationModalType } from "@types";
const { TextArea } = Input;

const { Button16Invert } = commonStyles;

export const CancelButton = styled(Button)`
  height: 48px;
  color: ${(props) => props.theme.datwylerText.textPrimary};
  padding: 12px 24px;
`;

const EscalationModal = ({
  open,
  setOpen,
  intl,
  handleEscalation,
}: EscalationModalType) => {
  const [form] = Form.useForm();

  const handleSubmit = ({ message }) => {
    handleEscalation(message);
  };

  return (
    <BasicModal
      title={intl.formatMessage({ id: "alarm_escalate_title" })}
      isOpen={open}
      handleCancel={() => setOpen(false)}
      footer={() => {
        return (
          <Space>
            <CancelButton onClick={() => setOpen(false)}>
              {intl.formatMessage({ id: "cancel_btn" })}
            </CancelButton>
            <Button16Invert htmlType="submit" type="primary">
              {intl.formatMessage({ id: "upload_btn" })}
            </Button16Invert>
          </Space>
        );
      }}
      modalRender={(dom) => (
        <Form layout="vertical" onFinish={handleSubmit} form={form}>
          {dom}
        </Form>
      )}
    >
      <Form.Item
        name="message"
        label={intl.formatMessage({ id: "alarm_message_field" })}
        rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "alarm_message_validation_msg" }),
          },
        ]}
      >
        <TextArea />
      </Form.Item>
    </BasicModal>
  );
};

export default EscalationModal;
