import { Form, Flex, DatePicker } from "antd";
import {
  BasicSelectMultiple,
  BasicButton,
  moment,
} from "@datwyler/mfe-shared-components";
import { FilterFormType } from "@types";

const { RangePicker } = DatePicker;

const FilterForm = ({
  form,
  intl,
  siteOptions,
  deviceOptions,
  levelOptions,
  statusOptions,
  userptions,
  onFinish,
  onReset,
}: FilterFormType) => {
  return (
    <>
      <Form name="filters" form={form} onFinish={onFinish}>
        <Flex vertical gap={16}>
          <Form.Item name="site" noStyle>
            <BasicSelectMultiple
              placeholder={intl.formatMessage({ id: "alarm_site_placeholder" })}
              options={siteOptions}
            />
          </Form.Item>
          <Form.Item name="deviceType" noStyle>
            <BasicSelectMultiple
              placeholder={intl.formatMessage({
                id: "alarm_device_placeholder",
              })}
              options={deviceOptions}
            />
          </Form.Item>
          <Form.Item name="severity" noStyle>
            <BasicSelectMultiple
              placeholder={intl.formatMessage({
                id: "alarm_level_placeholder",
              })}
              options={levelOptions}
            />
          </Form.Item>
          <Form.Item name="status" noStyle>
            <BasicSelectMultiple
              placeholder={intl.formatMessage({
                id: "alarm_status",
              })}
              options={statusOptions}
            />
          </Form.Item>
          <Form.Item name="assignedTo" noStyle>
            <BasicSelectMultiple
              placeholder={intl.formatMessage({
                id: "alarm_assigned_to",
              })}
              options={userptions}
            />
          </Form.Item>
          <Form.Item name="dateRange" noStyle>
            <RangePicker
              size="large"
              style={{ height: 56 }}
              disabledDate={(current) => {
                return current && current > moment().endOf("day");
              }}
            />
          </Form.Item>
          <Flex justify="end" gap={16}>
            <Form.Item noStyle>
              <BasicButton onClick={onReset}>
                {intl.formatMessage({ id: "reset_btn" })}
              </BasicButton>
            </Form.Item>
            <Form.Item noStyle>
              <BasicButton htmlType="submit" type={"primary"}>
                {intl.formatMessage({ id: "apply_btn" })}
              </BasicButton>
            </Form.Item>
          </Flex>
        </Flex>
      </Form>
    </>
  );
};

export default FilterForm;
