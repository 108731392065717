import { useContext } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { ConfigProvider } from "antd";
import {
  lodash as _,
  DarkModeContext,
  TenantIdProvider,
  UserProvider,
  PageNotFound,
} from "@datwyler/mfe-shared-components";
import Alarms from "@containers/alarms";
import { ThemeContextType } from "@types";
const App = ({ userProfileUri }: { userProfileUri: string }) => {
  const { theme } = useContext<ThemeContextType>(DarkModeContext);
  const location = useLocation();

  const tenantId = _.get(location, "state.tenantId");

  return (
    <UserProvider userProviderUri={userProfileUri}>
      <TenantIdProvider tenantId={tenantId}>
        <ConfigProvider theme={theme}>
          <Routes>
            <Route path="/alarm" element={<Alarms tenantId={tenantId} />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ConfigProvider>
      </TenantIdProvider>
    </UserProvider>
  );
};

export default App;
