import type { MappingAlgorithm, FormInstance } from "antd";
import type { AliasToken, OverrideToken } from "antd/es/theme/interface";
import { IntlShape } from "react-intl";

export enum EntityStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATED = "DEACTIVATED",
}

export enum Severity {
  CRITICAL = "CRITICAL",
  WARNING = "WARNING",
  INFORMATION = "INFORMATION",
}

export enum AlarmStatus {
  ACTIVE = "ACTIVE",
  IN_PROGRESS = "IN_PROGRESS",
  ESCALATED = "ESCALATED",
  RESOLVED = "RESOLVED",
  DEACTIVATED = "DEACTIVATED",
}
interface ThemeConfig<T = unknown> {
  token?: Partial<AliasToken> & T;
  components?: OverrideToken;
  algorithm?: MappingAlgorithm | MappingAlgorithm[];
  hashed?: boolean;
  inherit?: boolean;
}

export interface CustomThemePropType {
  datwylerBrand: Record<string, string>;
  datwylerText: Record<string, string>;
  datwylerBorder: Record<string, string>;
  datwylerIcon: Record<string, string>;
  datwylerSurface: Record<string, string>;
}

export type CustomThemeConfigProps = ThemeConfig<CustomThemePropType>;

export interface ThemeContextType {
  isDarkMode: boolean;
  isDarkModeAuto: boolean;
  theme: CustomThemeConfigProps;
}

export type TokenType = Partial<AliasToken> & CustomThemePropType;

export interface AlarmsContainerType {
  tenantId: string;
}

export interface EscalationLog {
  id: string;
  message: string;
  escalatedBy: {
    firstName: string;
    lastName: string;
  };
  escalatedOn: number;
}
export interface AlarmTableType {
  alarms: [];
  totalRows: number;
  rowsPerPage: number;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
  setOpenUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAlarm: React.Dispatch<React.SetStateAction<string>>;
  unassignAlarm: (alarmIds: string[]) => void;
  updateAlarm: (
    alarmIds: string[],
    status: AlarmStatus,
    message?: string
  ) => void;
  getEscalationLogs: (alarmId: string) => void;
  escalationLogs: EscalationLog[];
  updatedAlarms: { id: string }[];
}

export interface FilterFormDataType {
  site: { id: string }[];
  deviceType: [string];
  severity: [string];
  status: [string];
}

export interface AlarmFilterType {
  locations: { sites: { id: string }[] }[];
  devices: { type: string }[];
  users: { firstName: string; lastName: string; id: string }[];
  setFilters: React.Dispatch<React.SetStateAction<Partial<FilterFormDataType>>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

export interface FilterFormType {
  form: FormInstance;
  intl: IntlShape;
  siteOptions: { label: string; value: string }[];
  deviceOptions: { label: string; value: string }[];
  levelOptions: { label: string; value: string }[];
  statusOptions: { label: string; value: string }[];
  userptions: { label: string; value: string }[];
  onFinish: (formData: object) => void;
  onReset: () => void;
}

export interface UserSearchType {
  users: { firstName: string; lastName: string; id: string }[];
  openUserModal: boolean;
  setOpenUserModal: React.Dispatch<React.SetStateAction<boolean>>;
  intl: IntlShape;
  handleAssingTo: (user: string) => void;
}

export interface EscalationLogsType {
  intl: IntlShape;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: EscalationLog[];
}

export interface EscalationModalType {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  intl: IntlShape;
  handleEscalation: (message: string) => void;
}
