import { useState, useEffect } from "react";
import { Flex } from "antd";
import {
  BasicDataTable,
  useIntl,
  lodash as _,
  MoreButton,
  BasicButton,
  AssignIcon,
  commonStyles,
} from "@datwyler/mfe-shared-components";
import EscalationLogsTable from "@components/EscalationLogs";
import EscalationModal from "@components/EscalationModal";
import { AlarmTableType, AlarmStatus } from "@types";
import { getStatusTag, getSeverityBadge, getValidDate } from "@helpers";

const { Button14Brand } = commonStyles;

const Table = ({
  alarms,
  updatedAlarms,
  totalRows,
  pageNumber,
  escalationLogs,
  rowsPerPage,
  setPageNumber,
  setRowsPerPage,
  setOpenUserModal,
  setSelectedAlarm,
  unassignAlarm,
  updateAlarm,
  getEscalationLogs,
}: AlarmTableType) => {
  const intl = useIntl();
  const [showEscalationLogs, setShowEscalationLogs] = useState(false);
  const [showEscalationModal, setShowEscalationModal] = useState(false);
  const [selectedAlarms, setSelectedAlarms] = useState([]);
  const [selectedAlarmKeys, setSelectedAlarmKeys] = useState([]);
  const [buttonDisableProps, setButtonDisableProps] = useState({
    isDeactivateDisabled: false,
    isEscalatedDisabled: false,
  });

  useEffect(() => {
    let { isDeactivateDisabled, isEscalatedDisabled } = buttonDisableProps;
    selectedAlarms.forEach((element) => {
      isDeactivateDisabled = [
        AlarmStatus.DEACTIVATED,
        AlarmStatus.ESCALATED,
        AlarmStatus.IN_PROGRESS,
        AlarmStatus.RESOLVED,
      ].some((disableStatus) => disableStatus === element.status);
      isEscalatedDisabled = [
        AlarmStatus.DEACTIVATED,
        AlarmStatus.ESCALATED,
        AlarmStatus.RESOLVED,
      ].some((disableStatus) => disableStatus === element.status);
    });

    setButtonDisableProps({
      isDeactivateDisabled: isDeactivateDisabled,
      isEscalatedDisabled: isEscalatedDisabled,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAlarms, updatedAlarms]);

  const handleAssignTo = (alarmId) => {
    setSelectedAlarm(alarmId);
    setOpenUserModal(true);
  };

  const handleUpdateAlarm = (alarmId, status) => {
    updateAlarm([alarmId], status);
  };

  const handleRowSelection = (selectedRowKeys, selectedRows) => {
    setSelectedAlarmKeys([...selectedRowKeys]);
    setSelectedAlarms([...selectedRows]);
  };

  const handleDeactivation = () => {
    updateAlarm(selectedAlarmKeys, AlarmStatus.DEACTIVATED);
    setSelectedAlarmKeys([]);
    setSelectedAlarms([]);
  };

  const handleEscalation = (message) => {
    updateAlarm(selectedAlarmKeys, AlarmStatus.ESCALATED, message);
    setShowEscalationModal(false);
    setSelectedAlarmKeys([]);
    setSelectedAlarms([]);
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "alarm_id" }),
      key: "ID",
      dataIndex: "id",
      width: "50px",
    },
    {
      title: intl.formatMessage({ id: "alarm_device" }),
      key: "device",
      render: (row) => _.get(row, "device.name", ""),
    },
    {
      title: intl.formatMessage({ id: "alarm_site" }),
      key: "site",
      render: (row) => _.get(row, "device.site.name", ""),
    },
    {
      title: intl.formatMessage({ id: "alarm_attribute" }),
      key: "attribute",
      render: (row) =>
        intl.formatMessage({ id: _.get(row, "values.attribute_name") }),
    },
    {
      title: intl.formatMessage({ id: "alarm_description" }),
      key: "description",
      dataIndex: "description",
    },
    {
      title: intl.formatMessage({ id: "alarm_level" }),
      key: "level",
      render: ({ severity }) => getSeverityBadge(severity, intl),
    },
    {
      title: intl.formatMessage({ id: "alarm_date" }),
      key: "date",
      render: (row) => getValidDate(_.get(row, "time", ""), "DD-MM-YYYY HH:mm"),
    },
    {
      title: intl.formatMessage({ id: "alarm_status" }),
      key: "status",
      render: ({ status }) => getStatusTag(status, intl),
      align: "center",
    },
    {
      title: intl.formatMessage({ id: "alarm_assigned_to" }),
      key: "assigned_to",
      render: ({ id, assignedTo }) => {
        return (
          <>
            <Button14Brand
              type="text"
              icon={
                assignedTo ? null : (
                  <AssignIcon style={{ width: 20, height: 20 }} />
                )
              }
              onClick={() => handleAssignTo(id)}
              style={{ paddingLeft: 0 }}
            >
              {assignedTo
                ? `${assignedTo.firstName} ${assignedTo.lastName}`
                : "Assign To"}
            </Button14Brand>
          </>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "alarm_last_updated_by" }),
      key: "last_updated_by",
      render: (row) =>
        _.get(row, "updatedBy.firstName", "") +
        " " +
        _.get(row, "updatedBy.lastName", ""),
    },
    {
      title: intl.formatMessage({ id: "alarm_last_updated_time" }),
      key: "last_updated_time",
      render: (row) => {
        return getValidDate(_.get(row, "updatedOn", "-"), "DD-MM-YYYY HH:mm");
      },
    },
    {
      dataIndex: "action",
      render: (blank, alarm) => {
        return (
          <MoreButton
            items={[
              {
                label: intl.formatMessage({ id: "alarm_in_progress" }),
                disabled: [
                  AlarmStatus.DEACTIVATED,
                  AlarmStatus.RESOLVED,
                  AlarmStatus.IN_PROGRESS,
                ].some((disableStatus) => disableStatus === alarm.status),
                onClick: () =>
                  handleUpdateAlarm(alarm.id, AlarmStatus.IN_PROGRESS),
              },
              {
                label: intl.formatMessage({ id: "alarm_resolved" }),
                disabled: [
                  AlarmStatus.DEACTIVATED,
                  AlarmStatus.ACTIVE,
                  AlarmStatus.ESCALATED,
                  AlarmStatus.RESOLVED,
                ].some((disableStatus) => disableStatus === alarm.status),
                onClick: () =>
                  handleUpdateAlarm(alarm.id, AlarmStatus.RESOLVED),
              },
              {
                label: intl.formatMessage({ id: "alarm_deactivated" }),
                danger: true,
                disabled: [
                  AlarmStatus.DEACTIVATED,
                  AlarmStatus.ESCALATED,
                  AlarmStatus.IN_PROGRESS,
                  AlarmStatus.RESOLVED,
                ].some((disableStatus) => disableStatus === alarm.status),
                onClick: () =>
                  handleUpdateAlarm(alarm.id, AlarmStatus.DEACTIVATED),
              },
              {
                label: intl.formatMessage({ id: "alarm_escalation_logs" }),
                onClick: () => {
                  setShowEscalationLogs(true);
                  getEscalationLogs(alarm.id);
                },
              },
              {
                label: intl.formatMessage({ id: "alarm_unassigned" }),
                onClick: () => unassignAlarm([alarm.id]),
                disabled: !_.get(alarm, "assignedTo", true),
              },
            ]}
          />
        );
      },
    },
  ];

  return (
    <>
      <BasicDataTable
        rowSelection={{
          type: "checkbox",
          selectedRowKeys: selectedAlarmKeys,
          onChange: handleRowSelection,
        }}
        data={alarms}
        columns={columns}
        total={totalRows}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        onChangePage={(pageNumber) => {
          setPageNumber(pageNumber);
        }}
        onChangeRowsPerPage={(rowsPerPage) => {
          setRowsPerPage(rowsPerPage);
        }}
        rowKey={(record) => {
          return record.id;
        }}
      />
      {selectedAlarms.length > 0 && (
        <Flex justify="flex-end" gap={16}>
          <BasicButton
            disabled={buttonDisableProps.isDeactivateDisabled}
            danger
            variant="outlined"
            onClick={handleDeactivation}
          >
            {intl.formatMessage({ id: "alarm_deactivate_btn" })}
          </BasicButton>
          <BasicButton
            disabled={buttonDisableProps.isEscalatedDisabled}
            type="primary"
            onClick={() => setShowEscalationModal(true)}
          >
            {intl.formatMessage({ id: "alarm_escalate_btn" })}
          </BasicButton>
        </Flex>
      )}
      <EscalationLogsTable
        open={showEscalationLogs}
        setOpen={setShowEscalationLogs}
        intl={intl}
        data={escalationLogs}
      />
      <EscalationModal
        open={showEscalationModal}
        setOpen={setShowEscalationModal}
        intl={intl}
        handleEscalation={handleEscalation}
      />
    </>
  );
};

export default Table;
